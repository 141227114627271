<template>
	<div>
		<div v-if="$route.name == 'AdminErrorList'">
			<h2 class="mb-3">Feedback and error reports</h2>

			<grid-view api-url="admin/errorreports" delete-api-url="errorreports" link-user-name
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse"
					   collection-description="error reports"
					   item-name="error report"></grid-view>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'AdminErrorList',
		components: {
			GridView
		},
		data() {
			return {
				table: {
					fields: [
						{ key: 'edit', label: '', class: 'min' },
						{ key: 'reportStatus', label: 'Status', sortable: true },
						{ key: 'title', sortable: true },
						{ key: 'userName', sortable: true },
						//{ key: 'project', sortable: true },
						{ key: 'dateSubmitted', sortable: true, formatter: (value) => { return moment(value).format('llll') } },
						{ key: 'delete', label: '', class: 'min' }
					],
					sort: 'dateSubmitted',
					reverse: true,
					itemsPerPage: 50
				}
			}
		}
	}
</script>
