<template>
	<div>
		<b-container class="py-4" v-if="$route.name === 'ProjectList'">
			<auth-container :page="page">
				<b-tabs content-class="mt-4" class="page-nav-tabs">
					<template v-slot:tabs-end>
						<b-nav-item to="create" append><font-awesome-icon :icon="['fas', 'plus-square']" fixed-width class="text-info" /> New Project</b-nav-item>
					</template>
					<b-tab v-if="recent.length > 0" title="Recent Activity">
						<b-card-group deck>
							<b-card no-body class="border" v-for="(r, i) in recent" :key="i">
								<project-map :project-map="r.map" height="250px" :ref-name="`leafmap${i}`"></project-map>
								<b-card-body>
									<b-card-title class="text-center">{{r.name}}</b-card-title>
									<b-card-text>
										<div class="text-center">
											{{r.numSubbasins | number(0)}} subbasins /
											{{toAcres(r.totalArea, true) | number(2)}} ac
										</div>
									</b-card-text>
								</b-card-body>

								<router-link :to="`projects/${r.id}`" class="btn btn-primary btn-card-flush py-3">
									<small class="text-uppercase">Go to project</small>
								</router-link>
							</b-card>
						</b-card-group>
					</b-tab>
					<b-tab title="Projects">
						<grid-view api-url="projects" delete-api-url="projects" show-refresh ref="projectsGrid"
								   :fields="table.fields" link-name
								   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
								   collection-description="projects"
								   item-name="project"
								   create-route="create" show-create show-help></grid-view>
					</b-tab>
				</b-tabs>
			</auth-container>
		</b-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import ProjectMap from '@/components/ProjectMap';
	import moment from 'moment';

	export default {
		name: 'ProjectList',
		components: {
			GridView, ProjectMap
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: []
				},
				table: {
					fields: [
						{ key: 'name', sortable: true, class: 'min-w-200' },
						{ key: 'lastModified', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') }, class: 'nowrap' },
						{ key: 'dataset', sortable: false },
						{ key: 'endingSubbasin', label: 'Downstream ID', sortable: true },
						{ key: 'numSubbasins', label: 'Subbasins', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
						{ key: 'totalArea', label: 'Area (sq. km.)', sortable: false, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(2)) } },
						{ key: 'delete', label: '', sortable: false, class: 'min' }
					],
					sort: 'lastModified',
					reverse: true,
					itemsPerPage: 10
				},
				recent: []
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				if (this.$route.name === 'ProjectList') {
					this.page.errors = [];
					this.page.loading = true;

					try {
						const response = await this.$http.get(`projects/recent`, this.getTokenHeader());
						this.log(response.data);
						this.recent = response.data;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}

					this.page.loading = false;
				}
			},
			fromNow(d) {
				return moment(d).fromNow();
			},
			async refresh() {
				await this.get();
				await this.$refs.projectsGrid.get();
			}
		}
	}
</script>