<template>
	<div>
		<auth-container :page="page" require-admin>
			<h2 class="mb-4">Projects</h2>

			<p>
				Projects may be copied to a new user, or the same user. To copy a project,
				find it in the table below then
				<span v-b-tooltip.hover.bottom title="Click anywhere on the row except one of the icons or links" class="ml-1 border-bottom text-info" style="cursor:help">
					click on the row
				</span> to select it.
				Once you have selected a row it will highlight in blue. Scroll to the top of the page if needed, then click the copy project button.
			</p>

			<div v-if="selectedProject !== null">
				<b-alert variant="info" show>
					<p>
						You have selected project
						<strong>{{selectedProject.name}}</strong>
						assigned to user <strong>{{selectedProject.userName}}</strong>.
					</p>
					<b-button variant="primary" @click="page.copy.show = true">Copy Project</b-button>
				</b-alert>
			</div>

			<grid-view api-url="admin/projects" use-filter link-user-name
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
					   collection-description="projects"
					   item-name="project"
					   edit-route="/projects/" :edit-route-append="false"
					   selectable @selectionChanged="rowSelectionChanged"></grid-view>

			<b-modal v-model="page.copy.show" title="Copy plan">
				<error-list :errors="page.copy.errors"></error-list>

				<b-form-group label="Assign the copy to a different user by typing a user name below. Leave blank to use the same user as the original plan.">
					<b-typeahead ref="usersTypeahead"
								 :data="userNames"
								 v-model="usersSearch"
								 :max-matches="100">
					</b-typeahead>
				</b-form-group>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.copy.saving" @click.native="copyProject" text="Copy Project" variant="success" />
					<b-button type="button" variant="secondary" @click="page.copy.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</auth-container>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';
	import _ from 'underscore';

	export default {
		name: 'AdminProjects',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					copy: {
						show: false,
						errors: [],
						saving: false
					}
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'userName', sortable: true },
						{ key: 'name', sortable: true },
						{ key: 'lastModified', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') }, class: 'nowrap' },
						{ key: 'dataset', sortable: false },
						{ key: 'endingSubbasin', label: 'Downstream ID', sortable: true },
						{ key: 'numSubbasins', label: 'Subbasins', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
						{ key: 'totalArea', label: 'Area (sq. km.)', sortable: false, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(2)) } }
					],
					sort: 'lastModified',
					reverse: true,
					itemsPerPage: 50
				},
				selectedProject: null,
				usersSearch: '',
				userNames: []
			}
		},
		watch: {
			usersSearch: _.debounce(function (query) { this.findUsers(query) }, 500)
		},
		methods: {
			rowSelectionChanged(items) {
				this.log(items);
				if (items === null || items.length < 1) this.selectedProject = null;
				else {
					this.selectedProject = items[0];
				}
			},
			async copyProject() {
				this.page.copy.errors = [];
				this.page.copy.saving = true;

				try {
					var data = {
						value: this.usersSearch
					};
					this.log(data);
					const response = await this.$http.post(`admin/copyproject/${this.selectedProject.id}`, data, this.getTokenHeader());
					this.$router.push({ name: 'ProjectView', params: { id: response.data } }).catch(err => { this.log(err); });
					this.page.copy.show = false;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.copy.errors = this.logError(error);
				}

				this.page.copy.saving = false;
			},
			async findUsers(query) {
				try {
					const response = await this.$http.get(`/users/find/${query}`, this.getTokenHeader());
					this.userNames = response.data != '' ? response.data : [];
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.copy.errors = this.logError(error);
				}
			}
		}
	}
</script>