<template>
	<b-alert variant="warning" :show="errors.length > 0" :class="noBottomMarg ? 'mb-0' : 'mb-3'">
		<div v-for="(error, i) in errors" :key="i">{{error}}</div>
	</b-alert>
</template>

<script>
export default {
	name: 'ErrorList',
	props: {
		errors: {
			type: Array,
			required: true
		},
		noBottomMarg: {
			type: Boolean,
			default: false
		}
	}
}
</script>
