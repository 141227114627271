<template>
	<b-button type="button" :variant="variant" :size="size" :disabled="saving || disabled" @click="page.modal.show = true">
		<font-awesome-icon v-if="saving" :icon="['fas', 'spinner']" spin />
		{{ text }}

	<b-modal v-model="page.modal.show" title="Import a CSV file">
		<p>
			Download a CSV of your existing data first to get a template. Then, make and save your changes and upload it here.
		</p>

		<error-list :errors="page.modal.errors"></error-list>

		<b-form-file v-model="page.file" accept=".csv"
					 placeholder="Choose a file or drop it here..."
					 drop-placeholder="Drop file here..."></b-form-file>


		<div slot="modal-footer">
			<save-button type="button" :saving="page.modal.saving" @click.native="getCsv" text="Import" variant="success" :disabled="page.modal.saving" />
			<b-button type="button" variant="secondary" @click="page.modal.show = false" class="ml-1">Cancel</b-button>
		</div>
	</b-modal>
	</b-button>
</template>

<script>
	import Papa from "papaparse";

	export default {
		name: 'JsonToCsv',
		props: {
			saving: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
			filename: {
				type: String,
				default: 'data'
			},
			text: {
				type: String,
				default: 'Import CSV'
			},
			variant: {
				type: String,
				default: 'info'
			},
			size: {
				type: String,
				default: 'md'
			},
			convertToAcres: {
				type: Boolean,
				default: false
			},
			areaColumns: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				page: {
					modal: {
						show: false,
						saving: false,
						errors: []
					},
					file: null
				}
			}
		},
		methods: {
			getCsv() {
				this.page.modal.errors = [];
				if (this.page.file) {
					this.page.saving = true;
					let self = this;
					Papa.parse(this.page.file, {
						header: true,
						complete: function (results) {
							console.log("Finished:", results.data);
							self.page.saving = false;
							self.$emit('imported', results.data);
							self.page.modal.show = false;
						}
					});
				} else {
					this.page.modal.errors = ['Please select a file to import.'];
				}				
			}
		}
	}
</script>
