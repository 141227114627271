import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';
import Help from '../views/Help.vue';

import AdminDashboard from '../views/admin/AdminDashboard.vue';
import AdminProjects from '../views/admin/AdminProjects.vue';
import AdminErrorList from '../views/admin/errors/AdminErrorList.vue';
import AdminErrorView from '../views/admin/errors/AdminErrorView.vue';
import AdminWebsiteText from '../views/admin/AdminWebsiteText.vue';
import AdminDatasets from '../views/admin/datasets/AdminDatasets.vue';
import AdminDatasetView from '../views/admin/datasets/AdminDatasetView.vue';

import UserList from '../views/admin/users/UserList.vue';
import UserCreate from '../views/admin/users/UserCreate.vue';
import UserEdit from '../views/admin/users/UserEdit.vue';

import ProjectList from '../views/projects/ProjectList.vue';
import ProjectCreate from '../views/projects/ProjectCreate.vue';
import ProjectEdit from '../views/projects/ProjectEdit.vue';
import ProjectView from '../views/projects/ProjectView.vue';
import ProjectPrint from '../views/projects/ProjectPrint.vue';

import AccountProfile from '../views/account/AccountProfile.vue';
import AccountConfirm from '../views/account/AccountConfirm.vue';
import AccountReset from '../views/account/AccountReset.vue';
import AccountChangeEmail from '../views/account/AccountChangeEmail.vue';
import AccountRegister from '../views/account/AccountRegister.vue';

import FeedbackList from '../views/account/feedback/FeedbackList.vue';
import FeedbackView from '../views/account/feedback/FeedbackView.vue';
import FeedbackCreate from '../views/account/feedback/FeedbackCreate.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/', name: 'Home', component: Home
    },
    {
        path: '/help', name: 'Help', component: Help
    },
    {
        path: '/register', name: 'AccountRegister', component: AccountRegister
    },
    {
        path: '/account', name: 'AccountProfile', component: AccountProfile, meta: { title: 'Account' },
        children: [
            {
                path: 'confirm/:userId/:code', name: 'AccountConfirm', component: AccountConfirm
            },
			{
                path: 'reset/:code', name: 'AccountReset', component: AccountReset, meta: { title: 'Reset Password' }
            },
            {
                path: 'changeemail/:userId/:code/:email', name: 'AccountChangeEmail', component: AccountChangeEmail, meta: { title: 'Change Email' }
            }
        ]
    },
    {
        path: '/feedback', name: 'FeedbackList', component: FeedbackList, meta: { title: 'Feedback' },
        children: [
            {
                path: 'create', name: 'FeedbackCreate', component: FeedbackCreate, meta: { title: 'Create' }
            },
            {
                path: ':reportID', name: 'FeedbackView', component: FeedbackView, meta: { title: 'View' }
            }
        ]
    },
	{
        path: '/admin', name: 'AdminDashboard', component: AdminDashboard, meta: { title: 'Admin Dashboard' },
        children: [
            {
                path: 'users', name: 'UserList', component: UserList, meta: { title: 'Users' },
                children: [
                    {
                        path: 'create', name: 'UserCreate', component: UserCreate, meta: { title: 'Create' }
                    },
                    {
                        path: ':id', name: 'UserEdit', component: UserEdit, meta: { title: 'Edit' }
                    }
                ]
            },
            {
                path: 'projects', name: 'AdminProjects', component: AdminProjects, meta: { title: 'Projects' }
            },
            {
                path: 'errors', name: 'AdminErrorList', component: AdminErrorList, meta: { title: 'Error Reports' },
                children: [
                    {
                        path: ':id', name: 'AdminErrorView', component: AdminErrorView, meta: { title: 'Edit' }
                    }
                ]
            },
            {
                path: 'website', name: 'AdminWebsiteText', component: AdminWebsiteText, meta: { title: 'Website' }
            },
            {
                path: 'datasets', name: 'AdminDatasets', component: AdminDatasets, meta: { title: 'Datasets' },
                children: [
                    {
                        path: ':id', name: 'AdminDatasetView', component: AdminDatasetView, meta: { title: 'Details' }
                    }
                ]
            }
        ]
    },
    {
        path: '/projects', name: 'ProjectList', component: ProjectList, meta: { title: 'Projects' },
        children: [
            {
                path: 'create', name: 'ProjectCreate', component: ProjectCreate, meta: { title: 'Create' }
            },
            {
                path: ':id', name: 'ProjectView', component: ProjectView, meta: { title: 'View' },
                children: [
                    {
                        path: 'edit', name: 'ProjectEdit', component: ProjectEdit, meta: { title: 'Edit' }
                    }
                ]
            },
            {
                path: 'print/:id', name: 'ProjectPrint', component: ProjectPrint, meta: { title: 'Print' }
            }
        ]
    },
    {
        path: '*', redirect: '/projects'
    }
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
});

export default router
