<template>
	<div>
		<auth-container :page="page" require-admin v-if="$route.name == 'AdminDatasets'">
			<h2 class="mb-4">Datasets</h2>

			<grid-view api-url="admin/datasets" use-filter
					:fields="table.fields"
					:default-sort="table.sort" :default-reverse="table.reverse"
					collection-description="datasets"
					item-name="dataset"></grid-view>
		</auth-container>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'AdminDatasets',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'isActive', label: 'Active?', sortable: true, formatter: (value) => { return value ? '✓' : '' }, tdClass: 'text-success font-weight-bold text-center', thClass: 'text-center' },
						{ key: 'name', sortable: true },
						{ key: 'attribution', label: 'Credit', sortable: true },
						{ key: 'version', sortable: true, class: 'text-center' },
						{ key: 'createdOn', sortable: true, formatter: (value) => { return moment(value).format('YYYY') == '0001' ? '' : moment(value).format('llll') } },
						{ key: 'userName', label: 'Created By', sortable: true }
					],
					sort: '',
					reverse: false,
					itemsPerPage: 50
				}
			}
		}
	}
</script>
