import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
		token: localStorage.getItem('auth_token') || '',
		user: {
			userName: undefined,
			email: undefined,
			firstName: undefined,
			lastName: undefined,
			emailConfirmed: undefined,
			token: undefined,
			roles: []
		},
		siteText: {
			appName: undefined,
			siteName: undefined,
			siteLongName: undefined,
			siteNameDescription: undefined,
			homePageText: undefined,
			footer: undefined,
			helpPageText: undefined
		},
		mapColors: {
			subbasin: undefined,
			downstream: undefined,
			nearby: undefined,
			stream: undefined,
			selected: undefined,
			highContrast: undefined
		},
		rememberMeToken: undefined
    },
	mutations: {
		setupSite(state, payload) {
			state.siteText = payload.siteText;
		},
		login(state, payload) {
			state.token = payload.token;
			state.user = payload.user;
		},
		logout(state) {
			state.token = '';
			state.user = {
				email: undefined,
				firstName: undefined,
				lastName: undefined,
				emailConfirmed: undefined,
				token: undefined,
				roles: []
			};
		},
		setMapColors(state, payload) {
			state.mapColors = payload;
		},
		storeRememberMeToken(state, payload) {
			state.rememberMeToken = payload.token;
		}
    },
	getters: {
		isAuthenticated: state => !!state.token,
		user: state => state.user,
		roles: state => state.user.roles,
		token: state => state.token,
		siteText: state => state.siteText,
		mapColors: state => state.mapColors,
		rememberMeToken: state => state.rememberMeToken
	}
})
