<template>
	<div>
		<div v-html="siteText.homePageText"></div>

		<div class="py-4">
			<b-container>
				<footer-data></footer-data>
			</b-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Home'
	}
</script>
