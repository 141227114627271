import Vue from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';

Vue.mixin({
	data() {
		return {
			publicPath: process.env.BASE_URL,
			contactEmail: 'eco.web@tamu.edu',
			roleNames: {
				pending: 'Pending',
				user: 'User',
				admin: 'Admin',
				superAdmin: 'SuperAdmin'
			},
			globals: {
				mapColors: {
					default: {
						subbasin: '#e4fabe',
						downstream: '#b6dd6c',
						nearby: '#fff1d6',
						stream: '#006da0',
						selected: '#6fedda',
						highContrast: false
					},
					highContrast: {
						subbasin: '#FFB061',
						downstream: '#FF8000',
						nearby: '#fff1d6',
						stream: '#000FFF',
						selected: '#6fedda',
						highContrast: true
					}
				},
				passwordRequirements: 'Minimum 10 characters with at least one number, upper and lowercase letter, and special character'
			}
		}
	},
	watch: {
		'$route'() {
			var storedToken = localStorage.getItem('auth_token');
			if (!storedToken || (storedToken !== this.token)) this.$store.commit('logout');
		}
	},
	methods: {
		getTokenHeader() {
			return {
				headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') }
			};
		},
		isAuthorized(role) {
			return this.isAuthenticated && this.user.emailConfirmed && this.roles.includes(role);
		},
		logout(redirect = true) {
			localStorage.removeItem('auth_token');
			this.$store.commit('logout');
			if (redirect)
				this.$router.push('/').catch(err => { this.log(err) });
		},
		isApiUnauthorized(error) {
			return error.response && (error.response.status === 401 || error.response.status === 403);
		},
		isDevMode() {
			return process.env.NODE_ENV === 'development';
		},
		isNullOrEmpty(value) {
			return value === undefined || value === null || value === '';
		},
		capitalizeFirstLette(s) {
			return s.charAt(0).toUpperCase() + s.slice(1);
		},
		lowercaseFirstLetter(s) {
			return s.charAt(0).toLowerCase() + s.slice(1);
		},
		toValidFileName(s) {
			if (this.isNullOrEmpty(s)) return s;
			let val = s.replace(/[^A-Za-z0-9_\- ]/g, '');
			val = val.replace(/ /g, '-');
			val = val.replace(/---/g, '-');
			return val.toLowerCase();
		},
		numberWithCommas(x) {
			var parts = x.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return parts.join(".");
		},
		numberFixed(num, decimals) {
			if (this.isNullOrEmpty(num)) return num;
			return num.toFixed(decimals);
		},
		numberFormat(value, decimals = 1, units = '') {
			return this.numberWithCommas(Number(value).toFixed(decimals)) + units;
		},
		toDate(value, format = 'llll') {
			if (value) {
				return moment(String(value)).format(format);
			}
		},
		roundNumber(num) {
			return Math.round((num + Number.EPSILON) * 100) / 100;
		},
		sum(array, prop) {
			let total = 0;
			for (let obj of array) {
				total += Number(obj[prop]);
			}
			return total;
		},
		log(message) {
			if (this.isDevMode()) {
				console.log(message);
			}
		},
		logError(error, defaultMessage = undefined) {
			var messages = [];
			if (defaultMessage) {
				messages.push(defaultMessage);
			}

			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.log(error.response.data);
				//console.log(error.response.status);
				//console.log(error.response.headers);

				if (error.response.status === 401 || error.response.status === 403) {
					messages.push('Invalid credentials. Either your user name or password is incorrect, or you do not have permission to view this page.');
				} else if (error.response.status === 404) {
					messages.push('Requested data not found. Please check the URL in your browser and make sure you have the correct ID numbers if any.');
				} else if (error.response.data && error.response.data.errors) {
					var errors = error.response.data.errors;
					for (var key in errors) {
						for (var j = 0; j < errors[key].length; j++) {
							messages.push(errors[key][j]);
						}
					}
				} else if (error.response.data) {
					if (typeof error.response.data === 'string' || error.response.data instanceof String)
						messages.push(error.response.data);
					else
						messages.push('There was an error processing your request.');
				}

				if (error.response.data.stackTrace) {
					console.log(error.response.data.stackTrace);
				}
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}
			//console.log(error.config);

			return messages;
		},
		errorContainsKey(error, key) {
			return error.response && error.response.data && error.response.data.errors && Object.prototype.hasOwnProperty.call(error.response.data.errors, key); //error.response.data.errors.hasOwnProperty(key);
		},
		setMapColors(highContrast = true) {
			var colors = highContrast ? this.globals.mapColors.highContrast : this.globals.mapColors.default;
			localStorage.setItem('map_colors', JSON.stringify(colors));
			this.$store.commit('setMapColors', colors);
		},
		loadMapColors() {
			var storedColors = JSON.parse(localStorage.getItem('map_colors'));
			if (!storedColors) {
				storedColors = this.globals.mapColors.default;
				localStorage.setItem('map_colors', JSON.stringify(storedColors));
			}
			this.$store.commit('setMapColors', storedColors);
			this.log(storedColors);
			this.log(this.mapColors);
		},
		toAcres(value, doConvert) {
			if (!doConvert) return value;
			return value * 247.105;
		},
		toAcresFormatter(value, doConvert) {
			return this.numberFormat(this.toAcres(value, doConvert), 1, doConvert ? ' ac' : ' km2');
		},
		rand() {
			return Math.random().toString(36).substr(2); // remove `0.`
		},
		generateRand() {
			return this.rand() + this.rand();
		},
		setRememberMe(userName) {
			let storedRememberDeviceString = localStorage.getItem('remember_device');
			let storedRememberDevice = [];
			if (storedRememberDeviceString !== undefined && storedRememberDeviceString !== null) {
				storedRememberDevice = JSON.parse(storedRememberDeviceString);
				let remembered = storedRememberDevice.filter(function (el) { return el.user !== userName; });
				storedRememberDevice = remembered;
			}

			let token = this.generateRand();

			storedRememberDevice.push({
				user: userName,
				timestamp: moment().format(),
				token: token
			});

			localStorage.setItem('remember_device', JSON.stringify(storedRememberDevice));

			this.$store.commit('storeRememberMeToken', {
				token: token
			});

			this.log(storedRememberDevice);
			this.log(token);

			return token;
		},
		getRememberMe(userName) {
			let storedRememberDeviceString = localStorage.getItem('remember_device');
			this.log(storedRememberDeviceString);
			if (storedRememberDeviceString === undefined || storedRememberDeviceString === null) {
				return null;
			}

			let storedRememberDevice = JSON.parse(storedRememberDeviceString);
			let remembered = storedRememberDevice.filter(function (el) { return el.user === userName; });
			if (remembered === null || remembered.length < 1) {
				return null;
			}

			return remembered[0].token;
		}
	},
	computed: {
		...mapGetters(['isAuthenticated', 'user', 'roles', 'token', 'siteText', 'mapColors', 'rememberMeToken']),
		localStorageToken() {
			return localStorage.getItem('auth_token');
		},
		getValidState() {
			return (prop) => {
				return prop.$dirty ? !prop.$error : null;
			}
		},
		requiredFeedback() {
			return (prop) => {
				if (!prop.required) return 'Required';
			}
		}
	}
})