<template>
	<div>
		<auth-container :page="page">
			<h2 class="mb-4">Submit feedback</h2>

			<p>
				Complete the form below to contact system administrators. 
			</p>

			<b-form @submit.prevent="save">
				<error-list :errors="page.saveErrors"></error-list>

				<b-form-group label="Subject" :invalid-feedback="requiredFeedback($v.form.title)">
					<b-form-input v-model.trim="$v.form.title.$model" type="text" autofocus :state="getValidState($v.form.title)"></b-form-input>
				</b-form-group>

				<b-form-group label="Comments" :invalid-feedback="requiredFeedback($v.form.userComments)">
					<b-form-textarea v-model.trim="$v.form.userComments.$model" rows="10" :state="getValidState($v.form.userComments)"></b-form-textarea>
				</b-form-group>

				<fixed-action-bar :cols="12" :offset="0" :cols-lg="12" :offset-lg="0">
					<save-button :saving="page.saving" class="mr-2" />
					<back-button class="btn btn-secondary mr-2" />
				</fixed-action-bar>
			</b-form>
		</auth-container>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'FeedbackCreate',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false
				},
				form: {
					projectID: null,
					scenarioID: null,
					title: '',
					userComments: ''
				}
			}
		},
		validations: {
			form: {
				title: { required },
				userComments: { required }
			}
		},
		methods: {
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post(`errorreports`, this.form, this.getTokenHeader());
						this.log(response.data);
						this.$router.push({ name: 'FeedbackList' }).catch(err => { this.log(err) });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>
