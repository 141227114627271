<template>
	<b-container class="py-4">
		<h2 class="mb-4">SELECT Help and References</h2>

		<div v-html="siteText.helpPageText"></div>

		<hr class="my-5" />

		<footer-data></footer-data>
	</b-container>
</template>

<script>
	export default {
		name: 'Help'
	}
</script>
