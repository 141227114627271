<template>
	<div class="container py-5">
		<div v-if="!registration.submitted">
			<h2>Register for an account</h2>
			<p>
				Accounts are subject to administrator approval. Complete the form below to get started.
			</p>
		</div>

		<error-list :errors="registration.errors"></error-list>

		<div v-if="registration.submitted">
			<p>
				Your account has been created. Please check your email to confirm your account.
			</p>
			<p>
				Once confirmed, a HAWQS administrator will be notified to approve or deny access.
			</p>
			<p>
				If you do not receive the email after a few minutes, log into HAWQS using the user name and password you just created.
				You will be presented with the option to re-send the confirmation email.
			</p>
		</div>

		<b-form @submit.prevent="register" class="my-4" v-if="!registration.submitted">
			<b-row>
				<b-col md>
					<b-form-group label="First name" :invalid-feedback="requiredFeedback($v.registration.form.firstName)">
						<b-form-input v-model="registration.form.firstName" type="text" autofocus :state="getValidState($v.registration.form.firstName)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Last name" :invalid-feedback="requiredFeedback($v.registration.form.lastName)">
						<b-form-input v-model="registration.form.lastName" type="text" :state="getValidState($v.registration.form.lastName)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md>
					<b-form-group label="Email address">
						<b-form-input v-model="registration.form.email" type="email" :state="getValidState($v.registration.form.email)"></b-form-input>
						<b-form-invalid-feedback v-if="!$v.registration.form.email.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.registration.form.email.email">Please enter a valid email address</b-form-invalid-feedback>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Confirm your email">
						<b-form-input v-model="registration.form.compareEmail" type="email" :state="getValidState($v.registration.form.compareEmail)"></b-form-input>
						<b-form-invalid-feedback v-if="!$v.registration.form.compareEmail.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.registration.form.compareEmail.email">Please enter a valid email address</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.registration.form.compareEmail.sameAsEmail">Email addresses must match</b-form-invalid-feedback>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Company or organization" :invalid-feedback="requiredFeedback($v.registration.form.affiliation)">
				<b-form-input v-model="registration.form.affiliation" type="text" :state="getValidState($v.registration.form.affiliation)"></b-form-input>
			</b-form-group>

			<b-form-group label="Category" :invalid-feedback="requiredFeedback($v.registration.form.organizationCategory)">
				<b-form-select v-model="registration.form.organizationCategory" :options="location.organizationCategories" :state="getValidState($v.registration.form.organizationCategory)">
					<template v-slot:first>
						<option :value="null" disabled>-- Select a category --</option>
					</template>
				</b-form-select>
			</b-form-group>

			<b-form-group label="What is your reason for trying SELECT?" :invalid-feedback="requiredFeedback($v.registration.form.reasonForUse)">
				<b-form-input v-model="registration.form.reasonForUse" type="text" :state="getValidState($v.registration.form.reasonForUse)"></b-form-input>
			</b-form-group>

			<b-form-group label="In what country are you located?" :invalid-feedback="requiredFeedback($v.registration.form.country)">
				<b-form-select v-model="registration.form.country" :options="location.countries" :state="getValidState($v.registration.form.country)">
					<template v-slot:first>
						<option :value="null" disabled>-- Select a country --</option>
					</template>
				</b-form-select>
			</b-form-group>

			<b-form-group v-if="registration.form.country === 'United States'" label="Select your state or territory" :invalid-feedback="requiredFeedback($v.registration.form.state)">
				<b-form-select v-model="registration.form.state" :options="location.states" :state="getValidState($v.registration.form.state)">
					<template v-slot:first>
						<option :value="null" disabled>-- Select a state or territory --</option>
					</template>
				</b-form-select>
			</b-form-group>

			<hr class="my-3" />

			<b-form-group label="Enter a user name" :invalid-feedback="requiredFeedback($v.registration.form.userName)">
				<b-form-input v-model="registration.form.userName" type="text" :state="getValidState($v.registration.form.userName)"></b-form-input>
			</b-form-group>
			<b-row>
				<b-col md>
					<b-form-group label="Password" :description="globals.passwordRequirements">
						<b-form-input v-model="registration.form.password" type="password" :state="getValidState($v.registration.form.password)"></b-form-input>
						<b-form-invalid-feedback v-if="!$v.registration.form.password.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.registration.form.password.strongPassword">{{globals.passwordRequirements}}</b-form-invalid-feedback>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Confirm your password">
						<b-form-input v-model="registration.form.comparePassword" type="password" :state="getValidState($v.registration.form.comparePassword)"></b-form-input>
						<b-form-invalid-feedback v-if="!$v.registration.form.comparePassword.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.registration.form.comparePassword.sameAsPassword">Passwords must match</b-form-invalid-feedback>
					</b-form-group>
				</b-col>
			</b-row>

			<vue-recaptcha sitekey="6LfjtrQUAAAAAE4QWk_7aVBpuqZ4LvdCzLETUL6J"
						   ref="recaptcha"
						   @verify="submitCaptcha"
						   @expired="onCaptchaExpired"
						   :loadRecaptchaScript="true">
			</vue-recaptcha>

			<div class="mt-3">
				<save-button :saving="registration.saving" text="Create account" />
			</div>
		</b-form>
	</div>
</template>

<script>
	import { required, requiredIf, email, sameAs } from 'vuelidate/lib/validators';
	import VueRecaptcha from 'vue-recaptcha';

	export default {
		name: 'AccountRegister',
		components: {
			VueRecaptcha
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					saving: false,
					saveErrors: []
				},
				registration: {
					form: {
						firstName: '',
						lastName: '',
						email: '',
						compareEmail: '',
						userName: '',
						password: '',
						comparePassword: '',
						country: 'United States',
						state: null,
						affiliation: '',
						organizationCategory: null,
						reasonForUse: ''
					},
					errors: [],
					saving: false,
					submitted: false,
					recaptchaToken: ''
				},
				location: {
					countries: [],
					states: [],
					organizationCategories: []
				}
			}
		},
		validations: {
			registration: {
				form: {
					firstName: { required },
					lastName: { required },
					email: { required, email },
					compareEmail: { required, email, sameAsEmail: sameAs('email') },
					userName: { required },
					password: {
						required,
						strongPassword(password) {
							return (
								/[a-z]/.test(password) && // checks for a-z
								/[0-9]/.test(password) && // checks for 0-9
								/\W|_/.test(password) && // checks for special char
								password.length >= 10
							);
						}
					},
					comparePassword: { required, sameAsPassword: sameAs('password') },
					country: { required },
					state: { required: requiredIf(function () { return this.registration.form.country == 'United States'; }) },
					organizationCategory: { required },
					affiliation: { required },
					reasonForUse: { required }
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('account/locationdata', this.getTokenHeader());
					this.location = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async register() {
				this.registration.errors = [];
				this.registration.saving = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.registration.errors.push('Please fix the errors below and try again.');
				} else {
					var data = {
						firstName: this.registration.form.firstName,
						lastName: this.registration.form.lastName,
						email: this.registration.form.email,
						userName: this.registration.form.userName,
						password: this.registration.form.password,
						country: this.registration.form.country,
						state: this.registration.form.state,
						organizationCategory: this.registration.form.organizationCategory,
						affiliation: this.registration.form.affiliation,
						reasonForUse: this.registration.form.reasonForUse,
						recaptchaToken: this.registration.recaptchaToken
					};

					try {
						await this.$http.post('account/register', data);
						this.registration.submitted = true;
					} catch (error) {
						this.registration.errors = this.logError(error);
					}
				}

				this.registration.saving = false;
			},
			submitCaptcha(recaptchaToken) {
				this.registration.recaptchaToken = recaptchaToken;
			},
			onCaptchaExpired() {
				this.$refs.recaptcha.reset();
			}
		}
	}
</script>
