<template>
	<div>
		<div class="print-container">
			<auth-container :page="page">
				<div class="alert alert-info d-print-none my-4">
					This report is formatted to be printed from your browser window.
					You may choose map colors and sort tables as needed below.
					Google Chrome is recommended. Choose 'Save as PDF' option from the print menu to save to your computer.
					<div class="d-flex my-2">
						<div class="mr-2">
							<b-button variant="info" @click="print" class="mr-2"><font-awesome-icon :icon="['fas', 'print']" /> Print</b-button>
							<b-button variant="secondary" @click="close">Close</b-button>
						</div>
						<div class="ml-auto">
							<b-form-checkbox v-model="page.showAcres" switch @change="updateCharts">
								Show units in acres
							</b-form-checkbox>
							<div>
								<b-form-checkbox v-model="page.displayOptions" switch>
									Toggle print options
								</b-form-checkbox>
							</div>
						</div>
					</div>

					<b-collapse v-model="page.displayOptions" id="collapse-options" class="mt-2">
						<div class="mb-3">
							By default, all sections of the report are printed. Uncheck any sections you want to exclude:
						</div>
						<div><b-form-checkbox v-model="page.display.landCover">Land Cover</b-form-checkbox></div>
						<div><b-form-checkbox v-model="page.display.counties">Counties</b-form-checkbox></div>
						<div><b-form-checkbox v-model="page.display.total">Potential Total Load Summary</b-form-checkbox></div>
						<div><b-form-checkbox v-model="page.display.pets">Pets</b-form-checkbox></div>
						<div><b-form-checkbox v-model="page.display.livestock">Livestock</b-form-checkbox></div>
						<div><b-form-checkbox v-model="page.display.wildlife">Wildlife</b-form-checkbox></div>
						<div><b-form-checkbox v-model="page.display.ossf">OSSF</b-form-checkbox></div>
						<div><b-form-checkbox v-model="page.display.wwtf">WWTF</b-form-checkbox></div>
					</b-collapse>
				</div>

				<h2 class="mt-4 mb-1">
					SELECT Report - {{project.name}}
				</h2>
				<p>
					<em>Generated at <a href="https://select.tamu.edu">select.tamu.edu</a>, {{project.lastModified | date('MMMM Do YYYY, h:mm a')}}</em>
				</p>

				<p>
					<b>Subbasins:</b> {{project.numSubbasins | number(0)}}<br />
					<b>Total area:</b>
					{{toAcres(project.totalArea, page.showAcres) | number(2)}}
					<span v-if="page.showAcres">ac</span>
					<span v-else>km<sup>2</sup></span>
				</p>

				<project-map :project-map="project.map" height="400px" show-layer-control :show-acres="page.showAcres"></project-map>

				<div v-if="page.display.landCover">
					<hr class="my-4" />
					<div class="my-4">
						<highcharts :options="charts.landCover"></highcharts>
					</div>

					<div class="my-4">
						<highcharts :options="charts.landCoverPie"></highcharts>
					</div>

					<b-table class="page-break-before" bordered small :items="chartData.landCover" :fields="tables.landCover.fields" sort-by="y" sort-desc>
						<template v-slot:cell(percent)="data">
							{{data.item.y / project.totalArea * 100 | number(1)}} %
						</template>
					</b-table>
				</div>

				<div v-if="page.display.counties">
					<hr class="my-4 d-print-none" />
					<h2 class="mt-4 page-break-before">Counties</h2>

					<b-table bordered small :items="counties" :fields="tables.counties.fields"></b-table>

					<b-row class="mt-5">
						<b-col md="5">
							<highcharts :options="charts.percentWatershedInCounty"></highcharts>
						</b-col>
						<b-col md="5" offset="1">
							<highcharts :options="charts.percentCountyInWatershed"></highcharts>
						</b-col>
					</b-row>
				</div>

				<div v-if="page.display.total">
					<hr class="my-4 d-print-none" />
					<h2 class="mt-4 page-break-before">Potential Total Load</h2>

					<div class="d-flex d-print-flex">
						<div class="my-4 w-50">
							<map-visualizer :num-cols="12" option-button-below :geo-json-urls="project.map.geoJsonUrls" :map-data="totals.maps"></map-visualizer>
						</div>

						<div class="my-4 w-50">
							<highcharts :options="charts.loadSource"></highcharts>
						</div>
					</div>

					<div class="my-4">
						<highcharts :options="charts.loadBoxPlot"></highcharts>
					</div>

					<hr class="my-4 d-print-none" />
					<h3 class="mt-4 mb-2 page-break-before">Potential Loads by Subbasin</h3>
					<p><em>Load units are in billion cfu/day.</em></p>
					<b-table bordered small :items="totals.table" :fields="tables.totals.fields"></b-table>
				</div>

				<div v-if="page.display.pets">
					<hr class="my-4 d-print-none" />
					<h2 class="my-4 page-break-before">Pets</h2>

					<div class="my-4 d-flex d-print-flex">
						<div class="mr-2">
							<b-table-simple bordered small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Dog density</b-th>
										<b-td>{{project.inputs.dogDensity | number(2)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Cat density</b-th>
										<b-td>{{project.inputs.catDensity | number(2)}}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</div>
						<div>
							<b-table-simple bordered small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Fecal coliform to E. coli conversion factor</b-th>
										<b-td>{{project.inputs.petsFecalToEcoliRatio | number(2)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Fecal coliform production rate</b-th>
										<b-td>{{project.inputs.petsFecalProductionRate | number(0)}} cfu/head/day</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</div>
					</div>

					<map-visualizer :geo-json-urls="project.map.geoJsonUrls" :map-data="pets.maps"></map-visualizer>

					<h3 class="my-4">Potential Pet Load by Subbasin</h3>
					<b-table small bordered :items="pets.table" :fields="tables.pets.fields"></b-table>
				</div>

				<div v-if="page.display.livestock">
					<hr class="my-4 d-print-none" />
					<h2 class="my-4 page-break-before">Livestock</h2>

					<b-table-simple bordered small v-if="project.inputs.livestockFecalNumbers.length > 0">
						<b-thead>
							<b-tr>
								<b-th>Animal</b-th>
								<b-th>Fecal coliform to E. coli conversion factor</b-th>
								<b-th>Fecal coliform production rate</b-th>
							</b-tr>
						</b-thead>
						<b-tbody v-for="i in [0,1,2,3]" :key="i">
							<b-tr>
								<b-th rowspan="3">{{project.inputs.livestockFecalNumbers[i].animal}}</b-th>
								<b-td>{{project.inputs.livestockFecalNumbers[i].fecalToEcoliRatio | number(2)}}</b-td>
								<b-td>{{project.inputs.livestockFecalNumbers[i].fecalProductionRate | number(0)}} cfu/head/day</b-td>
							</b-tr>
							<b-tr>
								<b-th colspan="2">Stocking rates (acres/head)</b-th>
							</b-tr>
							<b-tr>
								<b-td colspan="2">
									<div v-for="(c, j) in project.map.connections" :key="j">
										{{project.inputs.subLivestockStockingRates[getLivestockIndex(c.fromID, project.inputs.livestockFecalNumbers[i].animal)].stockingRate | number(2)}} in
										{{c.fromName}}
									</div>
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<map-visualizer :geo-json-urls="project.map.geoJsonUrls" :map-data="livestock.maps"></map-visualizer>

					<h3 class="mt-4 mb-2">Potential Livestock Load by Subbasin</h3>
					<p><em>Load units are in billion cfu/day.</em></p>
					<b-table small bordered :items="livestock.table" :fields="tables.livestock.fields"></b-table>
				</div>

				<div v-if="page.display.wildlife">
					<hr class="my-4 d-print-none" />
					<h2 class="my-4 page-break-before">Wildlife</h2>

					<b-table-simple bordered small v-if="project.inputs.wildlifeFecalNumbers.length > 0">
						<b-thead>
							<b-tr>
								<b-th>Animal</b-th>
								<b-th>Fecal coliform to E. coli conversion factor</b-th>
								<b-th>Fecal coliform production rate</b-th>
							</b-tr>
						</b-thead>
						<b-tbody v-for="i in [0,1]" :key="i">
							<b-tr>
								<b-th rowspan="3">{{project.inputs.wildlifeFecalNumbers[i].animal}}</b-th>
								<b-td>{{project.inputs.wildlifeFecalNumbers[i].fecalToEcoliRatio | number(2)}}</b-td>
								<b-td>{{project.inputs.wildlifeFecalNumbers[i].fecalProductionRate | number(0)}} cfu/head/day</b-td>
							</b-tr>
							<b-tr>
								<b-th colspan="2">Stocking rates (acres/head)</b-th>
							</b-tr>
							<b-tr>
								<b-td colspan="2">
									<div v-for="(c, j) in project.map.connections" :key="j">
										{{project.inputs.subWildlifeStockingRates[getWildlifeIndex(c.fromID, project.inputs.wildlifeFecalNumbers[i].animal)].stockingRate | number(2)}} in
										{{c.fromName}}
									</div>
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<map-visualizer :geo-json-urls="project.map.geoJsonUrls" :map-data="wildlife.maps"></map-visualizer>

					<h3 class="mt-4 mb-2">Potential Wildlife Load by Subbasin</h3>
					<p><em>Load units are in billion cfu/day.</em></p>
					<b-table small bordered :items="wildlife.table" :fields="tables.wildlife.fields"></b-table>
				</div>

				<div v-if="page.display.ossf">
					<hr class="my-4 d-print-none" />
					<h2 class="my-4 page-break-before">On-Site Sewage Facilities</h2>

					<div class="my-4 d-flex d-print-flex">
						<div class="mr-2">
							<b-table-simple bordered small class="table-definitions" v-if="!project.inputs.customizeOssfBySubbasin">
								<b-tbody>
									<b-tr>
										<b-th>OSSF numbers</b-th>
										<b-td>{{project.inputs.ossfField}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Waste water</b-th>
										<b-td>{{project.inputs.ossfWasteWater | number(2)}} gal/person/day</b-td>
									</b-tr>
									<b-tr>
										<b-th>Failure rate</b-th>
										<b-td>
											<span v-if="project.inputs.useSoilClassFailureRate">Specified by soil drain field class</span>
											<span v-else>{{project.inputs.ossfFailureRate * 100 | number(0)}}%</span>
										</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</div>
						<div>
							<b-table-simple bordered small class="table-definitions">
								<b-tbody>
									<b-tr>
										<b-th>Fecal coliform to E. coli conversion factor</b-th>
										<b-td>{{project.inputs.ossfFecalToEcoliRatio | number(2)}}</b-td>
									</b-tr>
									<b-tr>
										<b-th>Fecal coliform concentration</b-th>
										<b-td>{{project.inputs.ossfFecalProductionRate | number(0)}} cfu/100ml</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</div>
					</div>

					<map-visualizer :num-cols="12" :geo-json-urls="project.map.geoJsonUrls" :map-data="ossf.maps"></map-visualizer>

					<h3 class="my-4">Potential OSSF Load by Subbasin</h3>
					<b-table small bordered :items="ossf.table" :fields="tables.ossf.fields"></b-table>
				</div>

				<div v-if="page.display.wwtf">
					<hr class="my-4 d-print-none" />
					<h2 class="my-4 page-break-before">Wastewater Treatment Facilities/Plants</h2>

					<b-table small bordered
							 :items="wwtfsUsed"
							 :fields="tables.wwtfInputs.fields">
						<template v-slot:cell(eColiDailyAverage)="data">
							<span :class="data.item.eColiDailyAverage === data.item.origEColiDailyAverage ? null : 'font-weight-bold'">
								{{data.item.eColiDailyAverage | number(2)}}
							</span>
						</template>
						<template v-slot:cell(flowLimit)="data">
							<span :class="data.item.flowLimit === data.item.origFlowLimit ? null : 'font-weight-bold'">
								{{data.item.flowLimit | number(2)}}
							</span>
						</template>
					</b-table>

					<map-visualizer :num-cols="12" :geo-json-urls="project.map.geoJsonUrls" :map-data="wwtf.maps"></map-visualizer>

					<h3 class="my-4">Potential WWTF/WWTP Load by Subbasin</h3>
					<b-table small bordered :items="wwtf.table" :fields="tables.wwtf.fields"></b-table>
				</div>

				<hr class="my-4 d-print-none" />
				<h2 class="my-4 page-break-before text-center">Acknowledgements</h2>
				<footer-data report-view></footer-data>
			</auth-container>
		</div>
	</div>
</template>

<script>
	import ProjectMap from '@/components/ProjectMap';
	import MapVisualizer from '@/components/MapVisualizer';

	export default {
		name: 'ProjectView',
		components: {
			ProjectMap, MapVisualizer
		},
		data() {
			return {
				projectId: this.$route.params.id,
				page: {
					errors: [],
					loading: true,
					showLogin: false,
					showAcres: true,
					displayOptions: false,
					display: {
						landCover: true,
						counties: true,
						total: true,
						pets: true,
						livestock: true,
						wildlife: true,
						ossf: true,
						wwtf: true
					}
				},
				project: {
					map: {},
					inputs: {
						dogDensity: 0,
						catDensity: 0,
						petsFecalToEcoliRatio: 0,
						petsFecalProductionRate: 0,
						subLivestockStockingRates: [],
						subWildlifeStockingRates: [],
						livestockFecalNumbers: [],
						wildlifeFecalNumbers: [],
						useSoilClassFailureRate: false,
						ossfFailureRate: 0,
						ossfWasteWater: 0,
						ossfFecalToEcoliRatio: 0,
						ossfFecalProductionRate: 0,
						ossfField: null,
						removedWwtfs: [],
						modifiedWwtfs: []
					}
				},
				counties: [],
				chartData: {},
				options: {
					landCovers: [],
					livestockAnimals: [],
					wildlifeAnimals: []
				},
				charts: {},
				pets: {
					table: [],
					maps: []
				},
				livestock: {
					table: [],
					maps: []
				},
				wildlife: {
					table: [],
					maps: []
				},
				ossf: {
					table: [],
					maps: []
				},
				wwtf: {
					table: [],
					maps: []
				},
				totals: {
					table: [],
					maps: [],
					charts: {
						loadSource: []
					}
				},
				tables: {
					landCover: {
						fields: [
							{ key: 'name', label: 'Land Cover', sortable: true },
							{ key: 'y', label: 'Area', sortable: true, formatter: (value) => { return this.toAcresFormatter(value, this.page.showAcres) }, class: 'text-right' },
							{ key: 'percent', label: 'Percent of Watershed', sortable: false, class: 'text-right' }
						]
					},
					counties: {
						fields: [
							{ key: 'county', sortable: true },
							{ key: 'countyArea', sortable: true, formatter: (value) => { return this.toAcresFormatter(value, this.page.showAcres) }, class: 'text-right' },
							{ key: 'areaInWatershed', sortable: true, formatter: (value) => { return this.toAcresFormatter(value, this.page.showAcres) }, class: 'text-right' },
							{ key: 'percentWatershedInCounty', sortable: true, formatter: (value) => { return this.numberFormat(value) + '%' }, class: 'text-right' },
							{ key: 'percentCountyInWatershed', sortable: true, formatter: (value) => { return this.numberFormat(value) + '%' }, class: 'text-right' }
						]
					},
					pets: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'dogLoad', sortable: true, label: 'Dog Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'catLoad', sortable: true, label: 'Cat Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							//{ key: 'population', sortable: true, formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' },
							{ key: 'housingUnits', sortable: true, formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' }
							//{ key: 'personsPerHousehold', sortable: true, label: 'Avg. Persons Per Household', formatter: (value) => { return this.numberFormat(value, 2) }, class: 'text-right' }
						]
					},
					livestock: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'cattleLoad', sortable: true, label: 'Cattle Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'sheepLoad', sortable: true, label: 'Sheep Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'goatLoad', sortable: true, label: 'Goat Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'horseLoad', sortable: true, label: 'Horse Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' }
						]
					},
					wildlife: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'deerLoad', sortable: true, label: 'Deer Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'feralHogLoad', sortable: true, label: 'Feral Hog Load', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' }
						]
					},
					ossf: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'ossf', sortable: true, label: 'OSSF#', formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' },
							{ key: 'wasteWater', sortable: true, label: 'Waste Water (gal/person/day)', formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' },
							{ key: 'failureRate', sortable: true, formatter: (value) => { return this.numberFormat(value, 2) }, class: 'text-right' },
							{ key: 'personsPerHousehold', sortable: true, label: 'Avg. Persons Per Household', formatter: (value) => { return this.numberFormat(value, 2) }, class: 'text-right' },
							{ key: 'load', sortable: true, label: 'Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' }
						]
					},
					wwtf: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'load', sortable: true, label: 'Load (billion cfu/day)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' }
						]
					},
					totals: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'totalLoad', sortable: true, label: 'Total', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'dogLoad', sortable: true, label: 'Dog', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'catLoad', sortable: true, label: 'Cat', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'cattleLoad', sortable: true, label: 'Cattle', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'sheepLoad', sortable: true, label: 'Sheep', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'goatLoad', sortable: true, label: 'Goat', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'horseLoad', sortable: true, label: 'Horse', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'deerLoad', sortable: true, label: 'Deer', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'feralHogLoad', sortable: true, label: 'Feral Hog', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'ossfLoad', sortable: true, label: 'OSSF', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'wwtfLoad', sortable: true, label: 'WWTF', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' }
						]
					},
					countyLivestock: {
						fields: [
							{ key: 'county', sortable: true },
							{ key: 'totalCountyArea', sortable: true, formatter: (value) => { return this.toAcresFormatter(value, this.page.showAcres) }, class: 'text-right' },
							{ key: 'suitableAreaInWatershed', sortable: true, label: 'Suitable Area', formatter: (value) => { return this.toAcresFormatter(value, this.page.showAcres) }, class: 'text-right' },
							{ key: 'cattle', sortable: true, formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' },
							{ key: 'sheep', sortable: true, formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' },
							{ key: 'goats', sortable: true, formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' },
							{ key: 'horses', sortable: true, formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' }
						]
					},
					subWildlife: {
						fields: [
							{ key: 'subbasin', sortable: true },
							{ key: 'ecoRegion', sortable: true },
							{ key: 'suitableDeerArea', sortable: true, label: 'Suitable Area for Deer', formatter: (value) => { return this.toAcresFormatter(value, this.page.showAcres) }, class: 'text-right' },
							{ key: 'deerDensity', sortable: true, label: 'Deer Rate (acre/AU)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'deer', sortable: true, label: '# Deer', formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' },
							{ key: 'suitableFeralHogsArea', sortable: true, label: 'Suitable Area for Feral Hogs', formatter: (value) => { return this.toAcresFormatter(value, this.page.showAcres) }, class: 'text-right' },
							{ key: 'feralHogsDensity', sortable: true, label: 'Feral Hog Rate (acre/AU)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'feralHogs', sortable: true, label: '# Feral Hogs', formatter: (value) => { return this.numberFormat(value, 0) }, class: 'text-right' }
						]
					},
					wwtfInputs: {
						fields: [
							{ key: 'facilityID', sortable: true, stickyColumn: false },
							{ key: 'subbasin', sortable: true },
							{ key: 'eColiDailyAverage', sortable: true, label: 'Daily Max. / Sample', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'flowLimit', sortable: true, label: 'Discharge (MGD)', formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							{ key: 'facilityName', sortable: true },
							//{ key: 'location', sortable: true },
							{ key: 'city', sortable: true },
							//{ key: 'latitude', sortable: true, formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' },
							//{ key: 'longitude', sortable: true, formatter: (value) => { return this.numberFormat(value) }, class: 'text-right' }
						]
					}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.projectId = this.$route.params.id;

				try {
					const response = await this.$http.get(`projects/print/${this.projectId}`, this.getTokenHeader());
					this.log(response.data);
					this.project = response.data.projectData.project;
					this.counties = response.data.projectData.counties;
					this.chartData = response.data.projectData.chartData;
					this.options = response.data.projectData.options;

					this.pets = response.data.pets;
					this.livestock = response.data.livestock;
					this.wildlife = response.data.wildlife;
					this.ossf = response.data.ossf;
					this.wwtf = response.data.wwtf;
					this.totals = response.data.totals;

					this.setCharts();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			setCharts() {
				let chartData = this.chartData;
				this.charts.percentWatershedInCounty = this.getPieChart('Percent of Watershed in County', chartData.percentWatershedInCounty);
				this.charts.percentCountyInWatershed = this.getColumnChart('Percent of County in Watershed', chartData.percentCountyInWatershed, 'Percent of County in Watershed', 'Percent', true);
				this.charts.landCover = this.getColumnChart('Land Cover', chartData.landCover, 'Area', this.page.showAcres ? 'Area in acres' : 'Area in sq. km', false, this.page.showAcres);
				this.charts.landCoverPie = this.getPieChart('Land Cover Distribution', chartData.landCover);

				this.charts.loadSource = this.getPieChart('Contribution to Potential Total Load', this.totals.charts.loadSource, 'Contribution');
				this.charts.loadBoxPlot = this.getBoxPlot(this.totals.charts.loadBoxPlot);
			},
			updateCharts() {
				this.$nextTick(function () {
					this.setCharts();
				});
			},
			getPieChart(title, data, seriesLabel = 'Area') {
				return {
					plotOptions: { pie: { dataLabels: { enabled: false }, showInLegend: true } },
					title: { text: title },
					tooltip: { pointFormat: '{series.name}: <b>{point.percentage:,.1f}%</b>' },
					series: [{ data: data, name: seriesLabel, type: 'pie' }]
				};
			},
			getColumnChart(title, data, seriesName, yAxisTitle = 'Values', isPercentage = false, doConvert = false) {
				let yAxis = {
					title: { text: yAxisTitle }
				};
				if (isPercentage) {
					yAxis = {
						min: 0,
						max: 100,
						title: { text: yAxisTitle }
					};
				}

				let convertedData = [];
				if (doConvert) {
					for (let row of data) {
						convertedData.push({
							name: row.name,
							y: this.toAcres(row.y, doConvert)
						});
					}
				}

				return {
					chart: {
						type: 'column'
					},
					title: {
						text: title
					},
					xAxis: {
						type: 'category'
					},
					yAxis: yAxis,
					legend: {
						enabled: false
					},
					tooltip: {
						pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y:,.1f}</b><br/>'
					},
					series: [{
						name: seriesName,
						colorByPoint: true,
						data: doConvert ? convertedData : data
					}]
				}
			},
			getBoxPlot(data) {
				let chart = {
					chart: { type: 'boxplot' },
					title: { text: data.title },
					xAxis: {
						//categories: data.xAxisCategories,
						categories: ['Load Type'],
						title: { text: null }
					},
					yAxis: {
						title: {
							text: 'Potential Load'
						},
						min: 0
					},
					series: data.series,
					plotOptions: {
						series: { turboThreshold: 0 }
					},
					exporting: {
						csv: { dateFormat: '%Y-%m-%d' }
					}
				};
				this.log(chart);
				return chart;
			},
			print() {
				window.print();
			},
			close() {
				window.close();
			},
			getLivestockIndex(subId, animal) {
				return this.project.inputs.subLivestockStockingRates.findIndex(x => x.subbasinId === subId && x.animal === animal);
			},
			getWildlifeIndex(subId, animal) {
				return this.project.inputs.subWildlifeStockingRates.findIndex(x => x.subbasinId === subId && x.animal === animal);
			}
		},
		computed: {
			wwtfsUsed() {
				if (this.options.wwtfs === undefined || this.options.wwtfs.count < 1) return [];
				return this.options.wwtfs.filter(function (el) { return el.included === true; });
			}
		}
	}
</script>