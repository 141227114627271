<template>
	<footer>
		<div v-html="siteText.footer"></div>
	</footer>
</template>

<script>
	export default {
		name: 'FooterData',
		props: {
			reportView: {
				type: Boolean,
				default: false
			}
		}
	}
</script>
