<template>
	<b-container fluid class="p-0">
		<auth-container :page="page" hide-errors>
			<error-list :errors="page.errors" no-bottom-marg></error-list>
			<b-row no-gutters>
				<b-col md="3" class="fixed-height shadow d-flex align-items-stretch flex-column" style="z-index:500">
					<error-list :errors="page.saveErrors"></error-list>

					<div class="px-3 py-4 bg-light border-bottom">
						Select a dataset from the dropdown below, then click on the map to select your watershed.
						The map will display all subbasins upstream of your click location, as well as nearby subbasins.
					</div>
					<div class="p-3">
						<b-form-group>
							<b-form-select v-model="project.datasetID" :options="options.datasets" @change="datasetChange">
							</b-form-select>
							<div v-if="!isNullOrEmpty(selectedDataset) && (!isNullOrEmpty(selectedDataset.description) || !isNullOrEmpty(selectedDataset.attribution))">
								<b-alert variant="light" show class="border mt-2 d-flex">
									<div>
										<font-awesome-icon :icon="['fas', 'info-circle']" class="text-info mr-2" />
									</div>
									<div>
										{{selectedDataset.description}}
										<div v-if="!isNullOrEmpty(selectedDataset.attribution)">
											<i>Credit: {{selectedDataset.attribution}}</i>
										</div>
									</div>
								</b-alert>
							</div>
						</b-form-group>
						<b-form-group label="Downstream subbasin">
							<b-typeahead ref="subbasinsTypeahead"
										 :data="options.subbasins"
										 v-model="subbasinsSearch"
										 :serializer="s => s.name"
										 :max-matches="100"
										 @hit="loadMapFromSub($event.name)">
								<template slot="append">
									<b-button @click="loadMapFromSub(subbasinsSearch)" variant="secondary" v-b-tooltip.hover="'Show watershed ending with this subbasin on map'">
										<font-awesome-icon :icon="['fas', 'map-marked-alt']" />
									</b-button>
								</template>
							</b-typeahead>
						</b-form-group>
						<div v-if="(hasWatershed() && map.route.length > 2) || startingSubs.length > 0 || options.selectStart">
							<b-button @click="options.showAdvanced = !options.showAdvanced" variant="light" size="sm" class="pr-3">
								<font-awesome-icon :icon="['fas', options.showAdvanced ? 'caret-down' : 'caret-right']" fixed-width />
								Advanced options
							</b-button>

							<b-collapse id="adv-collapse" v-model="options.showAdvanced">
								<p class="mt-3">
									By default your watershed is routed from the head to the downstream subbasin.
									If you want to start your project somewhere other than the head, toggle the button below
									and click the desired starting subbasins on the map. We recommend providing inlet data to your scenarios when utilizing this option.
								</p>
								<div class="mb-1">
									<b-button v-for="(s, i) in startingSubs" :key="i" variant="light" size="sm" class="mr-2 mb-2" v-b-tooltip.hover="options.selectStart ? 'Click to remove from list' : 'Toggle the select starting subbasins button to modify'" @click="removeStartingSub(s.id)">
										{{s.name}}
										<font-awesome-icon v-if="options.selectStart" :icon="['fas', 'times']" class="text-danger" />
									</b-button>
								</div>
								<p>
									<b-button :pressed.sync="options.selectStart" variant="primary" @click="loadMapFromIds">{{options.selectStart ? 'Done' : 'Begin'}} selecting starting subbasins</b-button>
								</p>
							</b-collapse>
						</div>
					</div>
					<div v-if="hasWatershed() && !options.selectStart" class="mt-4 p-3 border-top">
						<p>
							The selected watershed contains {{map.numSubs | number(0)}} subbasins.
							<a href="#" @click.prevent="modals.route.show=true">View routing table.</a>
						</p>

						<div>
							<b-form-checkbox v-if="map.route.length > 1" v-model="map.showRoute">Show routing on map</b-form-checkbox>

							<b-form-group label="Give your project a name" class="mt-4">
								<b-form-input v-model="project.name" type="text" :state="getValidState($v.project.name)"></b-form-input>
								<b-form-invalid-feedback v-if="!$v.project.name.required">Required</b-form-invalid-feedback>
								<b-form-invalid-feedback v-if="!$v.project.name.maxLength">Must be fewer than 100 characters</b-form-invalid-feedback>
								<b-form-invalid-feedback v-if="!$v.project.name.hasAlphaNumeric">Must contain at least one letter or number</b-form-invalid-feedback>
							</b-form-group>

							<b-button type="submit" variant="primary" @click="createProject">Create Project</b-button>
						</div>
					</div>

					<div class="d-none d-md-flex align-items-center border-top bg-white position-relative mt-auto">
						<div class="p-3">
							<router-link to="/projects" class="stretched-link text-reset" title="Back to project list">
								<font-awesome-icon :icon="['fas', 'chevron-left']" fixed-width />
							</router-link>
						</div>
						<div class="p-3 border-left text-uppercase">
							Back to project list
						</div>
					</div>
				</b-col>
				<b-col md="9">
					<div class="map-container full-map">
						<l-map ref="leafmap" style="height: 100%; width:100%" :zoom="map.zoom" :center="map.center" :bounds="map.bounds" @click="mapClick" @mousemove="mapMove">
							<l-control-layers position="topright"></l-control-layers>
							<l-tile-layer v-for="tileProvider in map.tileProviders"
										  :key="tileProvider.name"
										  :name="tileProvider.name"
										  :visible="tileProvider.visible"
										  :url="tileProvider.url"
										  :attribution="tileProvider.attribution"
										  layer-type="base" />
							<l-geo-json ref="subGeojson" v-if="map.subbasins.geojson !== undefined" :geojson="map.subbasins.geojson" :options="mapSubbasinsOptions" :options-style="mapSubbasinsStyle"></l-geo-json>
							<l-geo-json v-if="map.streams.geojson !== undefined" :geojson="map.streams.geojson" :options="map.streams.options"></l-geo-json>
							<l-control position="bottomleft">
								<div v-if="map.currentCoords !== undefined">Cursor position: {{map.currentCoords.lat | number(3)}}, {{map.currentCoords.lng | number(3)}}</div>
							</l-control>
							<div v-if="map.showRoute && map.route.length > 1">
								<l-polyline v-for="(c, i) in polylines" :key="i" :lat-lngs="c.lineCoordinates" color="green"></l-polyline>
								<v-polyline-decorator :paths="routeCoordinates" :patterns="map.patterns"></v-polyline-decorator>
							</div>
							<l-control position="bottomright">
								<b-button variant="dark" size="sm" @click="toggleColors" style="font-size:0.8rem">Toggle Contrast</b-button>
							</l-control>
						</l-map>
						<div v-if="map.loading" class="map-loading-overlay">
							<font-awesome-icon :icon="['fas', 'spinner']" spin size="3x" />
						</div>
					</div>
				</b-col>
			</b-row>

			<b-modal v-model="modals.route.show" scrollable size="md" title="Watershed Route">
				<b-table striped hover small no-sort-reset :items="map.route" :fields="['fromName', 'toName']"></b-table>

				<div slot="modal-footer">
					<b-button type="button" variant="primary" @click="modals.route.show = false">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.showLogin" size="md" hide-footer title="Please log in to continue">
				<login-form no-redirect @success="loginSuccess"></login-form>
			</b-modal>
		</auth-container>
	</b-container>
</template>

<script>
	import L from 'leaflet';
	import { LMap, LTileLayer, LGeoJson, LControl, LPolyline, LControlLayers } from 'vue2-leaflet';
	import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator';
	import _ from 'underscore';
	import 'leaflet/dist/leaflet.css';
	import { required, maxLength } from 'vuelidate/lib/validators';

	var __globalStartingSubs = []; //needed to sync with this.startingSubs because style function can't access 'this' and doesn't always touch line before return function in mapSubbasinsStyle
	var __globalColors = {};

	export default {
		name: 'ProjectCreate',
		components: {
			LMap, LTileLayer, LGeoJson, LControl, LPolyline, LControlLayers, 'v-polyline-decorator': Vue2LeafletPolylineDecorator
		},
		data() {
			return {
				page: {
					loading: false,
					errors: [],
					showLogin: false,
					saveErrors: [],
					saving: false
				},
				options: {
					datasets: [],
					subbasins: [],
					showAdvanced: false,
					selectStart: false
				},
				subbasinsSearch: '',
				project: {
					datasetID: 1,
					endID: undefined,
					startIDs: [],
					name: undefined,
					cacheID: undefined
				},
				startingSubs: [],
				map: {
					loading: false,
					zoom: 5,
					center: [39.8282, -98.5795],
					bounds: undefined,
					url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
					attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
					tileProviders: [
						{
							name: 'Default map',
							visible: true,
							attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
							url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
						},
						{
							name: 'Topography map',
							visible: false,
							url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
							attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
						},
						{
							name: 'Satellite map',
							visible: false,
							url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
							attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
						}
					],
					subbasins: {
						geojson: undefined
					},
					streams: {
						geojson: undefined,
						options: {
							style: {
								weight: 1,
								opacity: 1,
								color: '#006da0',
								dashArray: '0'
							}
						}
					},
					currentCoords: undefined,
					selectedPoint: undefined,
					numSubs: 0,
					numHrus: 0,
					route: [],
					showRoute: false,
					patterns: [
						{ offset: '50%', repeat: 0, symbol: L.Symbol.arrowHead({ pixelSize: 15, polygon: false, pathOptions: { color: 'green', stroke: true } }) }
					],
					id: undefined
				},
				modals: {
					route: {
						show: false
					}
				},
				selectedDataset: null
			}
		},
		validations: {
			project: {
				name: {
					required,
					maxLength: maxLength(100),
					hasAlphaNumeric(value) {
						return (
							/[a-z]/.test(value) || // checks for a-z
							/[0-9]/.test(value) // checks for 0-9
						);
					}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			subbasinsSearch: _.debounce(function (query) { this.findSubbasins(query) }, 500)
		},
		methods: {
			async get() {
				this.loadMapColors();
				this.map.streams.options.style.color = this.mapColors.stream;

				try {
					const response = await this.$http.get('/maps/datasets', this.getTokenHeader());
					this.log(response.data);
					this.options.datasets = response.data;
					this.map.bounds = L.latLngBounds(this.options.datasets[0].bounds);
					this.project.datasetID = this.options.datasets[0].value;

					var dataset = this.getSelectedDataset();
					if (dataset.loadByDefault && !this.isNullOrEmpty(dataset.endingSubbasin)) {
						await this.loadMapFromSub(dataset.endingSubbasin);
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			loginSuccess(wasSuccessful) {
				this.page.showLogin = !wasSuccessful;
			},
			async findSubbasins(query) {
				try {
					const response = await this.$http.get(`/maps/subbasins/find/${this.project.datasetID}/${query}`, this.getTokenHeader());
					this.options.subbasins = response.data != '' ? response.data : [];
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}
			},
			async datasetChange() {
				var dataset = this.getSelectedDataset();
				if (dataset.loadByDefault && !this.isNullOrEmpty(dataset.endingSubbasin)) {
					await this.loadMapFromSub(dataset.endingSubbasin);
				} else if (this.map.selectedPoint != undefined) {
					await this.loadMap(this.map.selectedPoint[0], this.map.selectedPoint[1]);
				} else {
					this.setBounds();
				}
			},
			getSelectedDataset() {
				var id = this.project.datasetID;
				var dataset = this.options.datasets.filter(function (el) { return el.value == id; });
				/*if (dataset.length == 0) {
					dataset = this.options.userDatasets.filter(function (el) { return el.value == id; });
				}*/
				this.selectedDataset = dataset[0];
				return dataset[0];
				//return this.options.datasets.filter(function (el) { return el.value == id; })[0]; 
			},
			setBounds() {
				var dataset = this.getSelectedDataset();
				if (dataset) {
					this.map.bounds = L.latLngBounds(dataset.bounds);
				}
			},
			async mapClick(e) {
				if (!this.options.selectStart) {
					await this.loadMap(e.latlng.lat, e.latlng.lng);
				}
			},
			async loadMap(lat, lon) {
				this.map.loading = true;
				this.page.errors = [];

				try {
					const response = await this.$http.get(`/maps/coords/${this.project.datasetID}/${lat}/${lon}`, this.getTokenHeader());

					this.map.selectedPoint = [lat, lon];
					this.setMapData(response.data);
					this.startingSubs = [];

					this.page.loading = false;

				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.map.loading = false;
			},
			async loadMapFromSub(name) {
				this.map.loading = true;
				this.page.errors = [];

				if (!this.isNullOrEmpty(name)) {
					try {
						const response = await this.$http.get(`/maps/subbasin/name/${this.project.datasetID}/${name}`, this.getTokenHeader());

						this.map.selectedPoint = undefined;
						this.setMapData(response.data);
						this.startingSubs = [];

						this.page.loading = false;

					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.map.loading = false;
			},
			getMapFromIdsDb() {
				var starting = '';
				for (var i = 0; i < this.startingSubs.length; i++) {
					starting += this.startingSubs[i].id.toString();
					if (i < this.startingSubs.length - 1)
						starting += ',';
				}
				return this.$http.get(`/maps/subbasin/id/${this.project.endID}/${starting}`, this.getTokenHeader());
			},
			async loadMapFromIds() {
				if (!this.options.selectStart) {
					this.map.loading = true;
					this.page.errors = [];

					try {
						const response = await this.getMapFromIdsDb();

						this.map.selectedPoint = undefined;
						this.setMapData(response.data);

						this.page.loading = false;

					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}

					this.map.loading = false;
				}
			},
			setMapData(map) {
				this.map.subbasins.geojson = map.subbasinsGeoJson;
				this.map.streams.geojson = map.streamsGeoJson;
				this.setEndingSubbasin(map.downstreamSubbasin);
				this.project.endID = map.downstreamID;

				this.map.bounds = L.latLngBounds(map.bounds);

				this.map.numSubs = map.numSubbasins;
				this.map.numHrus = map.numHrus;
				this.map.route = map.connections;
				this.map.id = map.id;

				if (!this.hasWatershed()) {
					this.setBounds();
				} else {
					var dataset = this.getSelectedDataset();
					if (dataset) {
						this.project.name = dataset.text + ' - ' + map.downstreamSubbasin;
					}
				}
			},
			mapMove(e) {
				this.map.currentCoords = e.latlng;
			},
			setEndingSubbasin(name) {
				this.subbasinsSearch = name;
				this.$refs.subbasinsTypeahead.$data.inputValue = name;
			},
			hasWatershed() {
				return !this.map.subbasins.geojson ? false : this.map.subbasins.geojson.features.length > 0;
			},
			async createProject() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					this.project.startIDs = [];
					for (var i = 0; i < this.startingSubs.length; i++) {
						this.project.startIDs.push(this.startingSubs[i].id);
					}

					this.project.cacheID = this.map.id;

					try {
						const response = await this.$http.post('projects/', this.project, this.getTokenHeader());
						this.$router.push({ name: 'ProjectView', params: { id: response.data } }).catch(err => { this.log(err) });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			mapSubbasinsClick(e) {
				if (this.options.selectStart && !e.target.feature.properties.isNearbyOnly) {
					var sub = e.target.feature.properties;
					var index = this.startingSubs.findIndex(x => x.id == sub.id);
					if (index === -1) {
						this.startingSubs.push({
							id: sub.id,
							name: sub.name
						});
					} else {
						this.removeStartingSub(sub.id);
					}
					__globalStartingSubs = this.startingSubs;
				}
			},
			removeStartingSub(id) {
				if (this.options.selectStart) {
					this.startingSubs = this.startingSubs.filter(function (el) { return el.id != id; });
					__globalStartingSubs = this.startingSubs;
				}
			},
			toggleColors() {
				this.setMapColors(!this.mapColors.highContrast);
				this.map.streams.options.style.color = this.mapColors.stream;
			}
		},
		computed: {
			polylines() {
				return this.map.route.filter(function (el) { return el.lineCoordinates[1].length > 0; })
			},
			mapSubbasinsOptions() {
				return {
					onEachFeature: this.mapSubbasinsOnEachFeature
				};
			},
			mapSubbasinsStyle() {
				__globalStartingSubs = this.startingSubs;
				__globalColors = this.mapColors;
				return (feature) => {
					var fillColor = feature.properties.isDownstream ? __globalColors.downstream : (feature.properties.isNearbyOnly ? __globalColors.nearby : __globalColors.subbasin);
					var index = __globalStartingSubs.findIndex(x => x.id == feature.properties.id);
					if (index > -1)
						fillColor = __globalColors.selected;

					return {
						fillColor: fillColor,
						weight: 1,
						opacity: 0.8,
						color: 'black',
						dashArray: '0',
						fillOpacity: feature.properties.isDownstream ? 0.9 : 0.5
					};
				};
			},
			mapSubbasinsOnEachFeature() {
				return (feature, layer) => {
					var streamName = feature.properties.name === feature.properties.streams ? '' : feature.properties.streams;
					layer.bindTooltip(
						'<div>' + feature.properties.name + '</div>' +
						'<div>' + streamName + '</div>' +
						'<div class="mt-2">' + this.numberWithCommas(feature.properties.area.toFixed(2)) + 'km<sup>2</sup> area</div>',
						{ permanent: false, sticky: true });

					layer.on({
						click: this.mapSubbasinsClick
					})
				}
			},
			routeCoordinates() {
				var plArray = [];
				for (var i = 0; i < this.map.route.length; i++) {
					if (this.map.route[i].lineCoordinates[1].length > 0)
						plArray.push(this.map.route[i].lineCoordinates);
				}
				return plArray;
			}
		}
	}
</script>