<template>
	<b-button type="button" :variant="variant" :size="size" :disabled="saving || disabled" @click="getCsv">
		<font-awesome-icon v-if="saving" :icon="['fas', 'spinner']" spin />
		{{ text }}
	</b-button>
</template>

<script>
	import Papa from "papaparse";
	import moment from 'moment';

	export default {
		name: 'JsonToCsv',
		props: {
			jsonData: {
				type: Array,
				default: () => []
			},
			saving: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
			filename: {
				type: String,
				default: 'data'
			},
			text: {
				type: String,
				default: 'Download CSV'
			},
			variant: {
				type: String,
				default: 'info'
			},
			size: {
				type: String,
				default: 'md'
			},
			convertToAcres: {
				type: Boolean,
				default: false
			},
			areaColumns: {
				type: Array,
				default: () => []
			}
		},
		methods: {
			getCsv() {
				var blob = new Blob([Papa.unparse(this.getData())], { type: 'text/csv;charset=utf-8;' });

				var link = document.createElement("a");
				var url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", `${this.filename}-${moment().format('YYYY-MM-DD')}.csv`);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
			getData() {
				if (!this.convertToAcres || this.areaColumns.length < 1) return this.jsonData;

				let newData = [];
				for (let r of this.jsonData) {
					let newRow = {};
					for (let k of Object.keys(r)) {
						newRow[k] = r[k];
						if (this.areaColumns.includes(k)) {
							newRow[k] = this.toAcres(r[k], this.convertToAcres)
						}
					}
					newData.push(newRow);
				}
				return newData;
			}
		}
	}
</script>
